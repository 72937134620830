<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Dashboard</span>
      </v-flex>
      <v-flex xs12 pt-5 v-if="position != '62a2de91ecd959516cd3b0e6'">
        <v-layout wrap>
          <v-flex xs12 sm6>
            <v-card>
              <v-layout wrap pa-5 justify-center>
                <v-flex xs12>
                  <span style="font-family: kumbhSemibold; font-size: 20px">{{
                    currentdate
                  }}</span>
                </v-flex>
                <v-flex xs12 py-5>
                  <v-layout wrap>
                    <v-flex xs12 sm6 md8>
                      <v-layout wrap>
                        <v-flex xs12 sm6>
                          <v-layout wrap>
                            <v-flex xs12>
                              <v-btn color="#005f32" outlined rounded>
                                <span
                                  style="
                                    font-family: kumbhBold;
                                    text-transform: none;
                                  "
                                  >Punch In</span
                                >
                              </v-btn>
                            </v-flex>
                            <v-flex xs12 pt-5 v-if="loginTime">
                              <span style="font-family: kumbhBold">{{
                                convertUTCToLocal(loginTime)
                              }}</span>
                            </v-flex>
                            <v-flex xs12 pt-5 v-else>
                              <span style="font-family: kumbhBold"
                                >Not Marked</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm6>
                          <v-layout wrap>
                            <v-flex xs12>
                              <v-btn color="#005f32" outlined rounded>
                                <span
                                  style="
                                    font-family: kumbhBold;
                                    text-transform: none;
                                  "
                                  >Punch Out</span
                                >
                              </v-btn>
                            </v-flex>
                            <v-flex xs12 pt-5 v-if="logoutTime">
                              <span style="font-family: kumbhBold">{{
                                convertUTCToLocal(logoutTime)
                              }}</span>
                            </v-flex>
                            <v-flex xs12 pt-5 v-else>
                              <span style="font-family: kumbhBold"
                                >Not Marked</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm6 md4 v-if="hourValue != 0">
                      <vue-ellipse-progress
                        :progress="value1"
                        :angle="-90"
                        color="green"
                        :size="100"
                        :thickness="10"
                        :legend="false"
                        :legendValue="0"
                        legendClass="legend-custom-style"
                        animation="reverse 700 400"
                        :noData="false"
                        :loading="false"
                        fontColor="red"
                        :half="false"
                        fontSize="5rem"
                      >
                        <!-- <span slot="legend-value">/200</span> -->
                        <p
                          class="pt-5"
                          slot="legend-caption"
                          style="color: black; font-weight: bold"
                        >
                          {{ hourValue }} hr
                        </p>
                      </vue-ellipse-progress>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout align-center justify-space-between>
                    <v-flex
                      xs12
                      sm6
                      text-start
                      v-if="
                        isLoggedin == false &&
                        isLoggedout == false &&
                        profile.onLeave == false
                      "
                    >
                      <v-btn
                        block
                        color="#005f32"
                        rounded
                        dark
                        @click="submit('login')"
                      >
                        <span style="font-family: kumbhSemibold">Punch In</span>
                      </v-btn>
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                      text-start
                      v-if="
                        isLoggedin == true &&
                        isLoggedout == false &&
                        profile.onLeave == false
                      "
                    >
                      <v-btn
                        block
                        color="#005f32"
                        rounded
                        dark
                        @click="submit('logout')"
                      >
                        <span style="font-family: kumbhSemibold"
                          >Punch Out</span
                        >
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 sm6 text-start v-if="profile.onLeave == true">
                      <v-btn block color="#005f32" rounded dark>
                        <span style="font-family: kumbhSemibold">On Leave</span>
                      </v-btn>
                    </v-flex>
                    <template>
                      <v-flex
                        xs12
                        sm2
                        align-self-center
                        text-end
                        v-if="
                        isLoggedin == true &&
                        isLoggedout == false &&
                        attendanceData.description
                      "
                      >
                          <v-btn
                            color="#005f32"
                            rounded
                            dark
                            small
                            @click="remarkdialogedit = true"
                          >
                            <span
                              style="
                                font-family: kumbhBold;
                                text-transform: none;
                                color: white;
                              "
                              >Notes <v-icon small> mdi-pencil </v-icon></span
                            >
                          </v-btn>
                      </v-flex>

                      <v-flex
                        xs12
                        sm2
                        align-self-center
                        text-end
                        v-if="
                          isLoggedin == true &&
                          isLoggedout == false &&
                          !attendanceData.description
                        "
                      >
                        <v-btn
                          color="#005f32"
                          rounded
                          dark
                          small
                          @click="remarkdialog = true"
                        >
                          <span
                            style="
                              font-family: kumbhBold;
                              text-transform: none;
                              color: white;
                            "
                            >Notes
                          </span>
                        </v-btn>
                      </v-flex>
                    </template>
                  </v-layout>
                </v-flex>
                <!-- <v-flex xs12 sm6 text-start
                  v-if="isLoggedin == false && isLoggedout == false && profile.onLeave == false">
                  <v-btn block color="#005f32" rounded dark @click="submit('login')">
                    <span style="font-family: kumbhSemibold">Punch In</span>
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm6 text-start
                  v-if="isLoggedin == true && isLoggedout == false && profile.onLeave == false">
                  <v-btn block color="#005f32" rounded dark @click="submit('logout')">
                    <span style="font-family: kumbhSemibold">Punch Out</span>
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm6 text-start v-if="profile.onLeave == true">
                  <v-btn block color="#005f32" rounded dark>
                    <span style="font-family: kumbhSemibold">On Leave</span>
                  </v-btn>
                </v-flex> -->
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-dialog persistent v-model="dialog" max-width="600px">
        <v-card>
          <v-layout wrap>
            <v-flex
              xs12
              pa-4
              text-left
              align-self-center
              style="background: #005f32 !important"
            >
              <v-layout wrap>
                <v-flex xs6 align-self-center text-left>
                  <span
                    class="kumbhBold"
                    style="color: #ffffff; font-size: 20px"
                    >Invalid Location</span
                  >
                </v-flex>
                <v-flex xs6 align-self-center text-right>
                  <v-btn icon color="white" text @click="dialog = false">
                    <v-icon color="#ffffff">mdi-close</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 pa-4></v-flex>
            <v-flex xs12>
              <v-layout wrap px-5 pb-8>
                <v-flex xs12 align-self-center text-left>
                  <span class="kumbhBold" style="color: #000; font-size: 20px"
                    >Please enable location access and try again</span
                  >
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
      <!-- <v-flex xs12 py-12>
        <v-layout wrap justify-center>
          <v-flex xs12 align-self-center lg4 pa-2 v-for="(item,i) in dummy" :key="i">
            <v-card color="#EEEECE" elevation="3" class="rounded-xl" outlined height="120px">
              <v-layout wrap justify-center fill-height>
                <v-flex xs9 align-self-center pa-2>
                  <span class="popsemibold">
                    {{ item.text }}
                  </span>
                </v-flex>
                <v-flex xs3 pa-2 align-self-center>
                  <span style="font-size:38px;color:red" class="popsemibold">
                    {{ item.count }}
                  </span>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex> -->
    </v-layout>

    <v-dialog persistent v-model="remarkdialog" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex
            xs12
            pa-4
            text-left
            align-self-center
            style="background: #005f32 !important"
          >
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px"
                  >Remarks</span
                >
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="remarkdialog = false">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 pt-2>
            <v-layout wrap px-5 pb-8>
              <v-flex xs12 align-self-center text-left>
                <span class="itemValue"> Description</span>
                <v-textarea
                  class="pt-2 text-des"
                  style="font-size: 14px"
                  v-model="description"
                  outlined
                  dense
                  hide-details
                ></v-textarea>
              </v-flex>

              <v-flex pt-2 xs12 align-self-center text-left>
                <span class="itemValue"> Upload Images</span>
                <v-layout wrap justify-center pa-5 style="border: solid 1px">
                  <v-flex xs12 text-left>
                    <v-layout wrap justify-start>
                      <v-flex
                        xs12
                        sm6
                        md4
                        lg4
                        pa-5
                        v-for="(items, index) in displayImage"
                        :key="index"
                      >
                        <v-img
                          :src="items"
                          fill-height
                          height="100%"
                          width="100%"
                        >
                          <v-layout wrap>
                            <v-flex text-right pa-0>
                              <v-avatar color="#FF3434" size="15">
                                <v-icon
                                  color="#FFF"
                                  small
                                  @click="removeImage(index)"
                                >
                                  mdi-close
                                </v-icon>
                              </v-avatar>
                            </v-flex>
                          </v-layout>
                        </v-img>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap justify-end>
                      <v-flex xs12 text-right>
                        <v-btn
                          color="blue-grey"
                          dark
                          small
                          @click="$refs.uploadDoc.click()"
                          class="itemform"
                          ><span> UPLOAD IMAGES </span>
                          <v-icon right dark> mdi-cloud-upload </v-icon></v-btn
                        >
                      </v-flex>
                      <input
                        v-show="false"
                        id="file1"
                        ref="uploadDoc"
                        multiple
                        type="file"
                        accept=".svg, .png, .jpg, .jpeg"
                        @change="uploadDoc"
                      />
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pb-3>
            <v-btn color="#005f32" @click="submitRemark()">
              <span class="itemValue" style="color: white"> Submit </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="remarkdialogedit" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex
            xs12
            pa-4
            text-left
            align-self-center
            style="background: #005f32 !important"
          >
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px"
                  >Edit Notes</span
                >
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn
                  icon
                  color="white"
                  text
                  @click="remarkdialogedit = false"
                >
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 pt-2>
            <v-layout wrap px-5 pb-8>
              <v-flex xs12 align-self-center text-left v-if="attendanceData">
                <span class="itemValue"> Description</span>
                <v-textarea
                  class="pt-2 text-des"
                  style="font-size: 14px"
                  v-model="attendanceData.description"
                  outlined
                  dense
                  hide-details
                ></v-textarea>
              </v-flex>

              <v-flex
                pt-2
                xs12
                align-self-center
                text-left
                v-if="attendanceData"
              >
                <span class="itemValue"> Images</span>
                <v-layout
                  wrap
                  justify-center
                  pa-5
                  style="border: solid 1px"
                  v-if="attendanceData.photos"
                >
                  <v-flex xs12 text-left>
                    <v-layout wrap justify-start>
                      <v-flex
                        xs12
                        sm6
                        md4
                        lg4
                        pa-5
                        v-for="(items, index) in attendanceData.photos"
                        :key="index"
                      >
                        <v-img
                          :src="mediaURL + items"
                          fill-height
                          height="100%"
                          width="100%"
                        >
                          <v-layout wrap>
                            <v-flex text-right pa-0>
                              <v-avatar color="#FF3434" size="15">
                                <v-icon
                                  color="#FFF"
                                  small
                                  @click="
                                    (deleteDialogImage = true),
                                      (imgItem = items)
                                  "
                                >
                                  mdi-close
                                </v-icon>
                              </v-avatar>
                            </v-flex>
                          </v-layout>
                        </v-img>
                      </v-flex>
                    </v-layout>
                    <!-- <v-layout wrap justify-end>
                      <v-flex xs12 text-right>
                        <v-btn color="blue-grey" dark small @click="$refs.uploadDoc.click()" class="itemform"><span>
                            UPLOAD IMAGES </span>
                          <v-icon right dark> mdi-cloud-upload </v-icon></v-btn>
                      </v-flex>
                      <input v-show="false" id="file1" ref="uploadDoc" multiple type="file"
                        accept=".svg, .png, .jpg, .jpeg" @change="uploadDoc" />
                    </v-layout> -->
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex pt-2 xs12 align-self-center text-left>
                <span class="itemValue"> Upload Images</span>
                <v-layout wrap justify-center pa-5 style="border: solid 1px">
                  <v-flex xs12 text-left>
                    <v-layout wrap justify-start>
                      <v-flex
                        xs12
                        sm6
                        md4
                        lg4
                        pa-5
                        v-for="(items, index) in displayImage"
                        :key="index"
                      >
                        <v-img
                          :src="items"
                          fill-height
                          height="100%"
                          width="100%"
                        >
                          <v-layout wrap>
                            <v-flex text-right pa-0>
                              <v-avatar color="#FF3434" size="15">
                                <v-icon
                                  color="#FFF"
                                  small
                                  @click="removeImage(index)"
                                >
                                  mdi-close
                                </v-icon>
                              </v-avatar>
                            </v-flex>
                          </v-layout>
                        </v-img>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap justify-end>
                      <v-flex xs12 text-right>
                        <v-btn
                          color="blue-grey"
                          dark
                          small
                          @click="$refs.uploadDoc.click()"
                          class="itemform"
                          ><span> UPLOAD IMAGES </span>
                          <v-icon right dark> mdi-cloud-upload </v-icon></v-btn
                        >
                      </v-flex>
                      <input
                        v-show="false"
                        id="file1"
                        ref="uploadDoc"
                        multiple
                        type="file"
                        accept=".svg, .png, .jpg, .jpeg"
                        @change="uploadDoc"
                      />
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 pb-3>
            <v-btn color="#005f32" @click="editSubmitRemark()">
              <span class="itemValue" style="color: white"> Submit </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="deleteDialogImage" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex
            xs12
            pa-4
            text-left
            align-self-center
            style="background: #005f32 !important"
          >
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                  Delete Image</span
                >
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn
                  icon
                  color="white"
                  text
                  @click="deleteDialogImage = false"
                >
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pa-4></v-flex>
          <v-flex xs12>
            <v-layout wrap px-5 pb-8>
              <v-flex xs12 align-self-center text-left>
                <span class="kumbhBold" style="color: #000; font-size: 20px"
                  >Are you sure you want to delete this Image?</span
                >
              </v-flex>

              <v-flex xs11 align-self-center text-right pt-3>
                <v-btn @click="deleteImage(imgItem)" color="#005f32">
                  <span class="kumbhBold" style="color: white"> Delete </span>
                </v-btn></v-flex
              >
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import store from "../../store";
// import Analytics from "./analytics";
// import GraphData from "./graphData";

export default {
  components: {
    // Analytics,
    // GraphData,
  },
  data() {
    return {
      imgItem: "",
      deleteDialogImage: false,
      remarkdialog: false,
      remarkdialogedit: false,
      editItem: "",
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dashboard: {},
      profile: {},
      isLoggedout: false,
      isLoggedin: false,
      value1: "",
      arr: [],
      loginTime: "",
      logoutTime: "",
      hr: "",
      hrs: "",
      hourValue: "",
      visible: false,
      level: "",
      dummy: [
        {
          text: "ANIMAL LIVES SAVED",
          count: 90,
        },
        {
          text: "AREA OF NATURAL HABITAT SECURED",
          count: 24,
        },
        {
          text: "THREATENED SPECIES RECOVERED",
          count: 26,
        },
        {
          text: "ELEPHANTS SAVED FROM TRAIN COLLISIONS",
          count: 46,
        },
        {
          text: "ENFORCEMENT OPERATIONS TO DISMANTLE WILDLIFE TRADE AND TRAFFICKING",
          count: 30,
        },
        {
          text: "FOREST GUARDS TRAINED AS A PART OF VAN RAKSHAK PROJECT",
          count: 177,
        },
        {
          text: "FAMILIES ENABLED WITH GREEN LIVELIHOODS, REDUCING DEPENDENCE ON WILDLIFE",
          count: 200,
        },
        {
          text: "NUMBER OF CHILDREN MADE AWARE OF CONSERVATION",
          count: 266,
        },
      ],
      lat: null,
      lon: null,
      note: "",
      address: "",
      maps: "",
      state: "",
      city: "",
      loc: "",
      currentdate: new Date().toDateString(),
      dialog: false,
      description: "",
      atndnce: [],
      docs1: [],
      docs1Public: [],
      docsPublic: [],
      docs: [],
      displayImage: [],
      userList: [],
      userArray: [],
      newArray: [],
      doc: null,
      file: null,
      keyword: "",
      registerAssets: {},
      formData: new FormData(),
      assetId: "",
      search: "",
      Rules: [(value) => !!value || "Required."],
      fileArray: [],
      byte: "",
      kb: "",
      mb: "",
      gb: "",
      attendanceData: {},
    };
  },
  computed: {
    appLogin() {
      if (store.state.isLoggedIn) this.getProfile();
      return store.state.isLoggedIn;
    },
    position() {
      return localStorage.getItem("positionId");
    },
  },
  beforeMount() {
    // this.getData();
    this.getProfile();
    this.getGeoLoaction();
  },
  methods: {
    clearForm() {
      this.description = "";
      this.displayImage = [];
      this.docs = [];
      this.fileArray = [];
    },
    removeImage(index) {
      this.displayImage.splice(index, 1);
      this.docs.splice(index, 1);
      this.fileArray.splice(index, 1);
    },

    // uploadDoc(event) {
    //   this.docs1 = event.target.files || event.dataTransfer.files;

    //   if (this.docs.length < 10) {
    //     for (let i = 0; i < this.docs1.length; i++) {
    //       if (this.fileArray.includes(this.docs1[i].name)) {
    //         this.msg = "Duplicate File";
    //         this.showSnackBar = true;
    //       } else {
    //         const urls = URL.createObjectURL(this.docs1[i]);
    //         this.displayImage.push(urls);
    //         this.docs.push(this.docs1[i]);
    //         console.log("file", this.docs1[i]);
    //         console.log("haii img", this.docs);
    //       }
    //       this.fileArray.push(this.docs1[i].name);
    //       this.checkbox = false;
    //       // this.checkbox1 = false;
    //       this.checkbox3 = false;
    //       // this.checkbox2 = false;
    //       this.docs1[i].sizeCopy = this.docs1[i].size + "Bytes";
    //       if (this.docs1[i].size >= 1024) {
    //         this.byte = this.docs1[i].size;
    //         this.kb = this.byte / 1024;
    //         if (this.kb >= 1024) {
    //           this.mb = (this.byte / 1048576).toFixed(2);
    //           if (this.mb >= 1024) {
    //             this.gb = (this.byte / 1073741824).toFixed(2);
    //             this.docs1[i].sizeCopy = this.gb + "GB";
    //           } else {
    //             this.docs1[i].sizeCopy = this.mb + "MB";
    //           }
    //         } else {
    //           this.docs1[i].sizeCopy = this.kb.toFixed(2) + "KB";
    //         }
    //       }
    //     }
    //     // this.addToArray();
    //   } else {
    //     this.msg = "Maximum 10 can be uploaded";
    //     this.showSnackBar = true;
    //   }
    // },

    uploadDoc(event) {
      this.docs1 = event.target.files || event.dataTransfer.files;

      if (this.docs.length < 10) {
        for (let i = 0; i < this.docs1.length; i++) {
          if (this.fileArray.includes(this.docs1[i].name)) {
            this.msg = "Duplicate File";
            this.showSnackBar = true;
          } else {
            const urls = URL.createObjectURL(this.docs1[i]);
            this.displayImage.push(urls);
            this.docs.push(this.docs1[i]);
          }
          this.fileArray.push(this.docs1[i].name);
        }
      } else {
        this.msg = "Maximum 10 images can be uploaded";
        this.showSnackBar = true;
      }
    },

    // submitRemark() {
    //   let formData = new FormData();

    //   // Add description to formData
    //   formData.append("description", this.description);
    //   formData.append("attendanceId", this.$route.query.id);

    //   // Add images to formData
    //   for (let i = 0; i < this.docs.length; i++) {
    //     formData.append("photos[]", this.docs[i]);
    //   }

    //   // Make API request to the backend route
    //   this.$axios.post('/attendance/note/update', formData, {
    //       headers: {
    //         'Content-Type': 'multipart/form-data',
    //         "x-auth-token": localStorage.getItem("token"),
    //       },
    //     })
    //     .then(response => {
    //       // Handle success
    //       console.log("Remark added successfully", response);
    //       this.$emit('close'); // Close dialog if you are emitting an event to close it
    //       this.clearForm();
    //     })
    //     .catch(error => {
    //       // Handle error
    //       console.error("Error adding remark", error);
    //     });
    // },

    submitRemark() {
      let formData = new FormData();

      // formData.append("attendanceId", this.$route.query._id);

      const selectedAttendance = this.atndnce.find(
        (item) => item.attendanceType === "login"
      ); // Adjust condition as needed

      // Get the _id from the selected attendance object
      const attendanceId = selectedAttendance ? selectedAttendance._id : null;
      if (!attendanceId) {
        this.msg = "No matching attendance record found.";
        this.showSnackBar = true;
        return;
      }

      formData.append("description", this.description);
      formData.append("attendanceId", attendanceId);
      for (let i = 0; i < this.docs.length; i++) {
        formData.append("photos", this.docs[i]);
      }

      this.appLoading = true;
      axios({
        method: "POST",
        url: "/attendance/note/update",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
        data: formData, // Use the local formData object, not this.formData
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.clearForm();
            this.showSnackBar = true;
            this.remarkdialog = false;
            this.getProfile();
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    editSubmitRemark() {
      let formData = new FormData();

      // formData.append("attendanceId", this.$route.query._id);

      const selectedAttendance = this.atndnce.find(
        (item) => item.attendanceType === "login"
      ); // Adjust condition as needed

      // Get the _id from the selected attendance object
      const attendanceId = selectedAttendance ? selectedAttendance._id : null;
      if (!attendanceId) {
        this.msg = "No matching attendance record found.";
        this.showSnackBar = true;
        return;
      }

      formData.append("description", this.attendanceData.description);
      formData.append("attendanceId", attendanceId);
      for (let i = 0; i < this.docs.length; i++) {
        formData.append("photos", this.docs[i]);
      }

      this.appLoading = true;
      axios({
        method: "POST",
        url: "/attendance/note/update",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
        data: formData, // Use the local formData object, not this.formData
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.remarkdialogedit = false;
            this.getProfile();
            this.showSnackBar = true;
            this.description = null;
            this.docs = [];
            this.displayImage = [];
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/dashboard/admin",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.dashboard = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getProfile() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/employee/profile/me",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          isAttendance: true,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.profile = response.data;
            this.atndnce = response.data.atndnce;
            localStorage.setItem("isloggedin", response.data.isLoggedin);
            this.isLoggedout = response.data.isLoggedout;
            this.isLoggedin = response.data.isLoggedin;
            this.loginTime = response.data.loginTime;
            this.logoutTime = response.data.logoutTime;
            this.attendanceData = this.atndnce.find(
              (item) => item.attendanceType === "login"
            ); // Adjust condition as needed

            // var start = new Date(this.loginTime).getTime() - 5.5 * 60 * 60000;
            // var end = new Date().getTime();
            var start = new Date(this.loginTime).getTime();
            var end = new Date().getTime();
            if (this.logoutTime != "")
              end = new Date(this.logoutTime).getTime();
            // end = new Date(this.logoutTime).getTime() - 5.5 * 60 * 60000;

            var hours = (end - start) / 3600 / 1000;
            console.log("time: ", new Date(this.loginTime), new Date());
            console.log("hours: ", hours);
            this.hr = end - start;
            console.log("ens and start", this.hr);
            // var timespan = 3196683;
            if (this.hr) {
              this.hourValue = this.msToHMS(this.hr);
            } else {
              this.hourValue = 0;
            }
            console.log("result", this.hourValue);
            var newValue = 100 / 8;
            this.value1 = hours * newValue;
            if (this.value1 > 99) {
              this.value1 = 99;
            }
            localStorage.setItem(
              "orgid",
              response.data.data.organisationID._id
            );
            localStorage.setItem("userid", response.data.data._id);
            this.level = response.data.data.level;
          } else if (response.data.status === false) {
            this.visible = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getGeoLoaction() {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.gettingLocation = false;
          this.location = pos;
          this.lat = this.location.coords.latitude;
          this.lon = this.location.coords.longitude;
          this.getStreetAddressFrom(this.lat, this.lon);
        },
        (err) => {
          this.gettingLocation = false;
          this.errorStr = err.message;
        }
      );
    },
    async getStreetAddressFrom(lat, long) {
      try {
        var { data } = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            lat +
            "," +
            long +
            "&key=AIzaSyBpukNl1L1qGqEwad87A1-MkZj8H_bKDZM"
        );
        if (data.error_message) {
          console.log(data.error_message);
        } else {
          // if(data.result[0]){
          this.address = data.results[0].formatted_address;
          //  if(data.result[0].address_components[0]){
          this.loc = data.results[0].address_components[0];
          // for(var i=0; i<this.loc.length;i++){
          //   this.state=this.loc[i].long_name
          // }
          this.state = data.results[0].address_components[2].long_name;
          this.city = data.results[0].address_components[1].long_name;
          console.log("state", this.state);
          console.log("city", this.city);

          //  }
          // }
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    submit(item) {
      console.log("data", item, " lon", this.lon, "lat", this.lat);
      if (!this.lon || !this.lat) {
        this.dialog = true;
      } else {
        axios({
          method: "POST",
          url: "/attendance/mark",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            // description: this.note,
            attendanceType: item,
            lon: this.lon,
            lat: this.lat,
            city: this.city,
            state: this.state,
            address: this.address,
            isWebsite: true,
          },
        })
          .then((response) => {
            if (response.data.status) {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.getProfile();
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((e) => {
            this.visible = false;
            this.errors.push(e);
            this.msg = "failed";
          });
      }
    },
    formatAMPM(time) {
      if (!time) return;
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    msToHMS(ms) {
      var seconds = ms / 1000;
      var hrs = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
      hrs = hrs < 10 ? "0" + hrs : hrs;
      seconds = seconds % 3600; // seconds remaining after extracting hrs
      var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds % 60;
      return hrs + " : " + minutes;
    },
    convertUTCToLocal(utcDateString) {
      // Create a new Date object from the UTC date string
      var utcDate = new Date(utcDateString);
      const hours = utcDate.getHours();
      const minutes = utcDate.getMinutes().toString().padStart(2, "0");
      const amPm = hours >= 12 ? "PM" : "AM";

      // Adjust hours for 12-hour format (convert from 24-hour to 12-hour)
      const adjustedHours = hours % 12 || 12;

      // Format the time string (HH:MM AM/PM)
      const formattedTime = `${adjustedHours}:${minutes} ${amPm}`;

      // Return the date and time as separate objects
      return formattedTime;
    },
    deleteImage(imgItem) {
      this.appLoading = true;
      const selectedAttendance = this.atndnce.find(
        (item) => item.attendanceType === "login"
      ); // Adjust condition as needed

      // Get the _id from the selected attendance object
      const attendanceId = selectedAttendance ? selectedAttendance._id : null;
      if (!attendanceId) {
        this.msg = "No matching attendance record found.";
        this.showSnackBar = true;
        return;
      }

      axios({
        method: "POST",
        url: "/attendance/photo/delete",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          attendanceId: attendanceId,
          photo: imgItem,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.getProfile();
            this.showSnackBar = true;
            this.deleteDialogImage = false;

            //this.getData();
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          // store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>